<template>
	<store-card :imageSrc="storeImage">
		<b-card-text> {{ store.storeName }} </b-card-text>

		<slot />

		<b-button @click="visitStore" size="sm">
			Visit Store
		</b-button>
	</store-card>
</template>

<script>
import { mapGetters } from "vuex";
import StoreCard from "../common/StoreCard.vue";

export default {
	components: { StoreCard },
	props: {
		store: {
			type: Object,
			required: true,
		},
	},
	computed: {
		...mapGetters({
			appShortLogo: "getAppShortLogo",
		}),
		storeImage() {
			const storeImage = this.store.storeImage;
			if (storeImage) return storeImage.blobUrl;
			return this.appShortLogo;
		},
		storeUrl() {
			const storeUrlName = this.store.storeUrlName;
			if (!storeUrlName) return null;
			return this.$options.filters.customerUrl("/".concat(storeUrlName));
		},
	},
	methods: {
		visitStore() {
			window.location.href = this.storeUrl;
		},
	},
};
</script>
