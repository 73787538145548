<template>
  <div>
    <h4>
      Stores near me
    </h4>

    <b-alert v-if="!geolocator" variant="info" show>
      Your browser does not support Geolocation. Cannot find stores around you.
    </b-alert>

    <b-alert v-else-if="geolocationError" variant="info" show>
      {{ geolocationError }}
    </b-alert>

    <b-alert v-else-if="!geolocationFound" variant="info" show>
      Kindly provide us access to your location to find stores around you. We
      cannot find stores if access is not granted!
    </b-alert>

    <b-alert v-else-if="stores.length < 1" variant="info" show>
      No stores found within {{ `${radiusInKm}km` }} radius.
    </b-alert>

    <!-- Store's row -->
    <b-container fluid v-else>
      <b-row>
        <b-col
          col
          cols="6"
          sm="4"
          md="3"
          lg="2"
          v-for="store in stores"
          :key="store.id"
          class="mb-2"
        >
          <visit-store-card :store="store" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Axios from "axios";
import VisitStoreCard from "./VisitStoreCard.vue";

export default {
  components: { VisitStoreCard },
  created() {
    if (this.geolocator) {
      this.geolocator.getCurrentPosition(
        this.onGeolocationFound,
        this.onGeolocationError,
        {
          enableHighAccuracy: true,
        }
      );
    }
  },
  data() {
    return {
      // location access
      geolocator: navigator.geolocation,
      geolocationError: false,
      geolocationFound: false,

      // filter
      radiusInKm: 50, //TODO: ask what value to set

      stores: [],
    };
  },
  methods: {
    onGeolocationFound(position) {
      if (position) this.geolocationFound = true;
      const { latitude, longitude } = position.coords;
      this.getStoreNearMe(latitude, longitude);
    },
    onGeolocationError(error) {
      const { code } = error;
      if (code === 1) {
        this.geolocationError =
          "You have denied us access to fetch your location.";
      }
      if (code === 2 || code === 3) {
        this.geolocationError =
          "Could not receive your location due to network issues. Refresh the page or Try again after some time.";
      }
    },
    getStoreNearMe(latitude, longitude) {
      const radiusInKm = this.radiusInKm;
      const params = { latitude, longitude, radiusInKm };
      Axios.get("/user/stores/near-me", { params }).then((data) => {
        const storeAndDistances = [...data];
        // sort nearest location first
        storeAndDistances.sort(
          (item1, item2) => item1.distance - item2.distance
        );
        // mapping storeAndDistances to only store
        this.stores = storeAndDistances.map((item) => item.store);
      });
    },
  },
};
</script>

<style></style>
