<template>
  <div>
    <h4>
      Recent Store Visited
    </h4>

    <b-alert v-if="userStoreHistory.length < 1" variant="info" show>
      You haven't visited any stores recently.
    </b-alert>

    <!-- Store's row -->
    <b-container v-else fluid>
      <b-row>
        <b-col
          col
          cols="6"
          sm="4"
          md="3"
          lg="2"
          v-for="history in userStoreHistory"
          :key="history.id"
          class="mb-2"
        >
          <visit-store-card :store="history.store" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VisitStoreCard from "./VisitStoreCard.vue";

export default {
  components: { VisitStoreCard },
  created() {
    this.initHistory();
  },
  computed: {
    ...mapGetters({
      userStoreHistory: "user/getUserStoreHistory",
    }),
  },
  methods: {
    ...mapActions({
      initHistory: "user/initHistory",
    }),
  },
};
</script>
