<template>
  <div>
    <b-tabs content-class="mt-3 mx-2" lazy justified>
      <!-- Store History -->
      <b-tab title="Recent Stores" active>
        <user-store-history />
      </b-tab>

      <!-- Search Store -->
      <b-tab title="Search Store">
        <user-search-store />
      </b-tab>

      <b-tab title="Stores near me">
        <user-stores-near-me />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import UserSearchStore from "../../components/buyer/UserSearchStore.vue";
import UserStoreHistory from "../../components/buyer/UserStoreHistory.vue";
import UserStoresNearMe from "../../components/buyer/UserStoresNearMe.vue";
export default {
  components: { UserStoreHistory, UserSearchStore, UserStoresNearMe },
};
</script>
