<template>
  <b-card class="store-card" no-body>
    <!-- Image Card -->
    <b-card class="image-card">
      <a @click="onClick" :class="[{ 'cursor-pointer': addImageClick }]">
        <b-img :src="imageSrc" style="height: 100%" fluid center />
      </a>
    </b-card>

    <b-card
      class="store-info-card border-0 mb-3"
      body-class="text-center p-1 mt-2"
    >
      <slot></slot>
    </b-card>
  </b-card>
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: String,
    },
    addImageClick: {
      type: Boolean,
    },
  },
  methods: {
    onClick() {
      if (this.addImageClick) this.$emit("click");
    },
  },
};
</script>

<style scoped>
.image-card {
  height: 100px;
}
</style>
