<template>
  <div>
    <h4>
      Search Store
    </h4>

    <b-form class="mb-3">
      <b-form-input
        type="search"
        id="name"
        v-model="name"
        @update="onFilterChange"
        placeholder="Search by store name"
        trim
      />
    </b-form>

    <b-alert v-if="count < 1" variant="info" show>
      No stores found.
    </b-alert>

    <template v-else>
      <!-- Store's row -->
      <b-container fluid>
        <b-row>
          <b-col
            col
            cols="6"
            sm="4"
            md="3"
            lg="2"
            v-for="store in stores"
            :key="store.id"
            class="mb-2"
          >
            <visit-store-card :store="store" />
          </b-col>
        </b-row>
      </b-container>

      <!-- Pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="count"
          :per-page="perPageCount"
          @change="getPaginatedStores"
          align="center"
        ></b-pagination>
      </div>
    </template>
  </div>
</template>

<script>
import Axios from "axios";
import VisitStoreCard from "./VisitStoreCard.vue";

export default {
  components: { VisitStoreCard },
  created() {
    this.initStores();
  },
  data() {
    return {
      name: "",

      stores: [],

      // for pagination
      currentPage: 1,
      perPageCount: 24,
      count: 10,
    };
  },
  computed: {
    filterParams() {
      return {
        name: this.name,
        page: this.currentPage - 1,
        size: this.perPageCount,
        sort: "id,desc", // for sorting by id desc
      };
    },
  },
  methods: {
    initStores() {
      const params = { ...this.filterParams };
      Axios.get("/user/stores/count", { params }).then((data) => {
        const storesCount = data.count;
        this.count = storesCount;
        this.getPaginatedStores();
      });
    },
    onFilterChange() {
      // re-init count & stores
      this.initStores();
    },
    getPaginatedStores(currentPage) {
      // first set the page
      this.currentPage = currentPage;

      const params = { ...this.filterParams };
      Axios.get("/user/stores", { params }).then((data) => {
        this.stores = [...data];
      });
    },
  },
};
</script>

<style></style>
